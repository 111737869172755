import React, { useContext, useEffect } from "react"
import { Link, navigate } from "gatsby"
import classNames from "classnames"

import Layout from "../Layout/Layout"
import Container from "layout/Container"
import ActionButtons from "elements/ActionButtons"
import ConsentAndAuthorization from "elements/ConsentAndAuthorization"
import { Brand } from "../Elements/Brand"

import { Mobile } from "elements/Contact"
import { AppContext } from "../../context/AppContext"
import {
  GASTBY_ENCRYPTED_KEY,
  GATSBY_ENCRYPTED_VALUE,
} from "gatsby-env-variables"

const EnrollmentMechanics = props => {
  const { cta, nextPath, module, mechanic, basePath } = props.pageContext
  const nextLink = module?.sku
    ? `/${module.sku}/${module.name}/${nextPath}`
    : `/${module.name}/${nextPath}`
  const { state, dispatch } = useContext(AppContext)

  const handleFormSubmit = values => {
    sessionStorage.setItem(GASTBY_ENCRYPTED_KEY, GATSBY_ENCRYPTED_VALUE)
    dispatch({
      type: "SAVE_ACKNOWLEDGEDMENT",
      payload: values.hasAcknowledge,
    })

    navigate(nextLink)
  }

  useEffect(() => {
    // if (module.name === "doctor") {
    //   navigate(basePath)
    // }
    navigate("/")
  }, [module])

  return (
    <div></div>
    // <Layout
    //   title={
    //     <span className="is-size-3-mobile">
    //       Welcome to the PatientLink
    //       <br className="is-hidden-mobile" /> Sampling Program for{" "}
    //       <Brand type={module.sku} />
    //     </span>
    //   }
    //   seoTitle={`${module.seoTitle} Mechanics`}
    //   // display={{ helpCenterBanner: true }}
    // >
    //   <Container isCentered>
    //     <div
    //       className="mb-2"
    //       dangerouslySetInnerHTML={{
    //         __html: mechanic,
    //       }}
    //     ></div>
    //     {module.name === "patient" && (
    //       <ConsentAndAuthorization handleOnSubmit={handleFormSubmit}>
    //         <ActionButtons
    //           submit={{
    //             label: "Enroll as Patient",
    //             disabled: state?.consentAndAuth.length === 0,
    //             className: "mb-2",
    //           }}
    //         />
    //       </ConsentAndAuthorization>
    //     )}
    //     {module.name === "doctor" && (
    //       <div className="is-flex is-justify-content-center">
    //         <Link
    //           to={nextLink}
    //           className={classNames("button mt-2 mr-1 is-primary is-medium")}
    //         >
    //           {cta}
    //         </Link>
    //       </div>
    //     )}
    //     {module?.subtitle && (
    //       <div className={classNames("is-flex mt-1 is-justify-content-center")}>
    //         <section
    //           dangerouslySetInnerHTML={{ __html: module?.subtitle }}
    //         ></section>
    //       </div>
    //     )}
    //     {/* <p className="mt-2">
    //       Have other questions about the program? Get answers immediately from
    //       our{" "}
    //       <a
    //         href="/help-center"
    //         target="_blank"
    //         className="has-text-weight-bold has-text-primary"
    //       >
    //         Help Center
    //       </a>{" "}
    //       or contact us at <Mobile />.
    //     </p> */}
    //   </Container>
    // </Layout>
  )
}

export default EnrollmentMechanics
