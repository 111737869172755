import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faBuilding,
  faHospital,
} from "@fortawesome/free-solid-svg-icons"

import { ErrorMessage } from "formik"

const addressTypes = [
  {
    value: "Home",
    icon: faHome,
  },
  {
    value: "Office",
    icon: faBuilding,
  },
  {
    value: "Hospital",
    icon: faHospital,
  },
]

const AddressTypeChoices = ({
  addressTypeName,
  isRequired,
  hideOptional,
  values,
  setFieldValue,
}) => (
  <div className="mb-1">
    <p className="is-size-5">
      Address Type{" "}
      {!isRequired && !hideOptional && (
        <span className="is-italic has-text-grey">(Optional)</span>
      )}
    </p>
    <div className={classNames("mt-1 mb-0")}>
      {addressTypes.map((addressType, index) => (
        <button
          key={index}
          onClick={() => {
            setFieldValue(addressTypeName || "addressType", addressType.value)
          }}
          className={classNames("button mr-1 mb-1", {
            "is-primary":
              values?.deliveryAddress?.addressType === addressType.value,
          })}
          type="button"
        >
          <span className="icon is-small">
            <FontAwesomeIcon icon={addressType.icon} />
          </span>
          <span>{addressType.value}</span>
        </button>
      ))}
    </div>
    <p className="help mt-0 mb-1 is-danger">
      <ErrorMessage name={addressTypeName} />
    </p>
  </div>
)

export default AddressTypeChoices
