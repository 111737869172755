import React, { useRef, useEffect } from "react"
import { camelize } from "humps"
import classNames from "classnames"
import { Field, ErrorMessage, useFormikContext } from "formik"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import AsyncSelect from "react-select/async"
import AsyncCreatableSelect from "react-select/async-creatable"

import handleScrollToError from "./utils/handleScrollError"
import { generateFormField, getFollowUpQuestionData } from "./services/form"

/**
 ** Select field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'option': Object [],
 **               'onChange': Function,
 **               'value': Object {},
 **             }
 **
 ** Note: You need a list of objects as options with label and value element.
 **/

const customStyles = theme => ({
  ...theme,
  boxShadow: "inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.05)",
  colors: {
    ...theme.colors,
    primary: "#6ea9a9",
    primary25: "#cfe1e1",
    primary50: "#9ec5c5",
  },
})

const FormSelect = props => {
  //* destructure props
  const {
    name,
    label,
    onChange,
    isRequired,
    isSelectable,
    hideOptional,
    followUpQuestions,
    formFields,
    formValues,
    setFieldValue,
  } = props

  const formik = useFormikContext()
  const fieldRef = useRef(null)

  const handleScrollCallback = () => {
    fieldRef.current.scrollIntoView({ block: "center" })
  }

  useEffect(() => {
    handleScrollToError({
      formikContext: formik,
      fieldName: name,
      callback: handleScrollCallback,
    })
  }, [formik.submitCount, formik.isValid, formik, name])

  //* Function to set the value of the react-select in
  //* formik values.
  //*
  //* Note: Curried Function.
  //*       Need to call handleChange(form) to return (selectedValue) => { ... }
  const handleChange = form => selectedValue => {
    form.setFieldValue(name, selectedValue)
    if (onChange) onChange(selectedValue, form.setFieldValue)
  }

  const SelectComponent = ({ form }) => {
    const Selector = props.isAsync ? AsyncSelect : Select
    return (
      <Selector
        {...props}
        className="is-size-6"
        onChange={handleChange(form)}
        theme={customStyles}
      />
    )
  }

  const CreatableSelectComponent = ({ form }) => {
    const Selector = props.isAsync ? AsyncCreatableSelect : CreatableSelect
    return (
      <Selector
        {...props}
        className="is-size-5"
        theme={customStyles}
        onChange={handleChange(form)}
        isDisabled={props.isDisabled}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    )
  }

  return (
    <div className="field mb-2">
      <label
        className={classNames("label has-text-weight-normal is-size-5")}
        ref={fieldRef}
      >
        {label}
        {!isRequired && !hideOptional && (
          <span className="has-text-grey is-italic"> (Optional)</span>
        )}
        {!!props.helper && (
          <span
            className={classNames(
              "help has-text-weight-normal",
              props.helperClassName
            )}
          >
            {props.helper}
          </span>
        )}
      </label>
      <Field>
        {({ form }) =>
          isSelectable ? (
            <CreatableSelectComponent form={form} />
          ) : (
            <SelectComponent form={form} />
          )
        }
      </Field>
      <div>
        {followUpQuestions?.map(followUpQuestion => {
          const getFormField = getFollowUpQuestionData({
            followUpQuestion,
            formFields: formFields,
          })
          if (
            getFormField?.referenceAnswer === camelize(formValues[name].value)
          )
            return (
              <div className="mt-2">
                {generateFormField({
                  formField: { ...getFormField },
                  formFields,
                  values: formValues,
                  setFieldValue,
                })}
              </div>
            )
          return null
        })}
      </div>
      <p className="help is-danger">
        <ErrorMessage name={`${name}.value`} />
      </p>
    </div>
  )
}

export default FormSelect
